import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import GetStarted from "../../Components/GetStarted/GetStarted";

import "./Contact.scss";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contactMain">
      <div className="banner-sec mb-2">
        <div className="container d-flex align-items-center justify-content-center h-100">
          <div className="heading">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <Row>
          <Col md={6}>
            <div className="contactIntro">
              <div className="head1">
                <h2>Get In Touch</h2>
              </div>
              <div className="text-div">
                <p>
                For more information about Kaytree please email.
                </p>
              </div>
              <div className="cont">
                <h4>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-telephone"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg>{" "}
                  Phone
                </h4>
                <a href="tel:+6122580491"><p>+612-258-0491</p></a>
              </div>
              <div className="cont">
                <h4>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                  </svg>{" "}
                  Email
                </h4>
                <a href="mailto:info@kaytree.com"><p>info@kaytree.com</p></a>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="card formDiv">
              <div className="head1">
                <h2>Let's Talk</h2>
              </div>
              <form>
                <div className="inputDiv">
                  <label>
                    Name<sup>*</sup>
                  </label>
                  <input type="text" />
                </div>
                <div className="inputDiv">
                  <label>
                    Email<sup>*</sup>
                  </label>
                  <input type="email" />
                </div>
                <div className="inputDiv">
                  <label>
                    Subject<sup>*</sup>
                  </label>
                  <input type="text" />
                </div>
                <div className="inputDiv">
                  <label>
                    Message<sup>*</sup>
                  </label>
                  <textarea rows={5} type="text" />
                </div>

                <div className="btn-div">
                  <button>Search</button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
      <GetStarted />
    </div>
  );
}

export default Contact;
