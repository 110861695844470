import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import {
  getChatHead,
  getChats,
  getCompanionChats,
  getJobsWithApplicantsInProgress,
  sendMessage,
} from "../../redux/jobSlice/actions";
import "./ChatComponent.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateChatHead } from "../../redux/userSlice/actions";

const socket = io("https://kaytreebackend-63da29f5d5cb.herokuapp.com/");
// const socket = io("https://kaytreebackend-63da29f5d5cb.herokuapp.com/");

function ChatComponent() {
  let [lastOpenChat, setLastOpenChat] = useState(false);
  let [openChats, setOpenChats] = useState([]);

  let dispatch = useDispatch();

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        // If the user has already granted permission
        // new Notification("Hello! You already granted permission.");
      } else if (Notification.permission !== "denied") {
        // Request permission from the user
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            // new Notification("Hello! Thanks for granting permission.");
          }
        });
      }
    }

    socket.on("chatMessage", (data) => {
      dispatch(getChats(data.project_id)).then((res) => {
        console.log(res?.payload?.data?.chats[0]?.sender_id?.name);
        let name = res?.payload?.data?.chats[0]?.sender_id?.name;
        toast.success(`You have a new message ${name}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
        });
        const options = {
          body: data.message,
          icon: require("../../assets/images/logo.png"), // Path to an icon image
          vibrate: [200, 100, 200], // Vibration pattern for mobile devices
          tag: "unique-tag", // Prevents multiple notifications of the same type
          renotify: false, // Prevents re-notifying the user
        };

        new Notification(`You have a new message `, options);
        setTimeout(() => {
          var element = document.getElementById(`message-${data.project_id}`);
          if (element) {
            element.scrollTop = element.scrollHeight;
          }
        }, 500);
        if (data?.receiver_id === user?._id) {
          dispatch(getChatHead()).then((res) => {
            if (res?.payload?.data) {
              let count = res?.payload?.data?.reduce(
                (a, b) => a.count + b.count
              );
              if (count > 0) {
                document.getElementById("badgeNew").style.display = "flex";
                document.getElementById("badgeNew").innerHTML = count;
              } else {
                document.getElementById("badgeNew").style.display = "none";
              }
            }
          });
        }
      });
      if (Notification.permission === "granted") {
        // Optionally, you can add an event listener for the notification click
        // notification.onclick = (event) => {
        //     event.preventDefault(); // Prevent default action (e.g., opening a link)
        //     window.focus(); // Focus the window (optional)
        // };
      }
      dispatch(getChatHead(data?.project_id));
    });

    return () => {
      socket.off("chatMessage");
    };
  }, [dispatch]);

  const openChatHead = (id) => {
    if (
      document.getElementById(id).style.height == "50px" ||
      document.getElementById(id).style.height == ""
    ) {
      document.getElementById(id).style.height = "500px";
    } else {
      document.getElementById(id).style.height = "50px";
    }
  };

  const setChat = (a) => {
    if (user.role === "loved-one") {
      dispatch(getChats(a._id)).then((res) => {
        let arr = [...openChats];
        let checking = arr.filter((b) => b._id === a._id);
        if (!checking.length) {
          arr.push(a);
          setOpenChats(arr);
          setLastOpenChat(`chat-${a._id}`);
        }
        setTimeout(() => {
          var element = document.getElementById(`message-${a._id}`);
          if (element) {
            element.scrollTop = element.scrollHeight;
          }
        }, 500);
      });
    } else {
      dispatch(getChats(a?.job?._id)).then((res) => {
        let arr = [...openChats];
        let checking = arr.filter((b) => b._id === a._id);
        if (!checking.length) {
          arr.push(a);
          setOpenChats(arr);
          setLastOpenChat(`chat-${a._id}`);

          setTimeout(() => {
            var element = document.getElementById(`message-${a._id}`);
            if (element) {
              element.scrollTop = element.scrollHeight;
            }
          }, 500);
        }
      });
    }
  };

  useEffect(() => {
    if (openChats.length) {
      document.getElementById(lastOpenChat).style.height = "500px";
    }
  }, [lastOpenChat]);

  const removeChat = (i) => {
    let arr = [...openChats];
    arr.splice(i, 1);
    setOpenChats(arr);
  };

  let chats = useSelector((state) => state.job.chats);
  let user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user.role === "loved-one") {
      dispatch(getJobsWithApplicantsInProgress()).then((res) => {});
    } else {
      dispatch(getCompanionChats()).then((res) => {});
    }
  }, []);

  let [text, setText] = useState("");

  const send = (a, e) => {
    e.preventDefault();
    let obj = {};
    if (a) {
      obj.receiver_id =
        user?.role === "loved-one"
          ? a?.applications[0]?.applicant?._id
          : a?.createdBy?._id;
      obj.message = text;
      obj.project_id = user?.role === "loved-one" ? a?._id : a?.job?._id;
      console.log(a);

      // obj.job_id = a?.job?._id;
    }

    dispatch(sendMessage(obj)).then((res) => {
      if (user.role === "loved-one") {
        dispatch(getChats(a._id)).then((res) => {
          setText("");
          socket.emit("chatMessage", obj);
          dispatch(
            updateChatHead({
              id: a?.applications[0]?.applicant?._id,
              data: { type: "increment", jobId: a?._id },
            })
          );
        });
      } else {
        dispatch(getChats(a?.job?._id)).then((res) => {
          setText("");
          socket.emit("chatMessage", obj);
          dispatch(
            updateChatHead({
              id: a?.createdBy?._id,
              data: { type: "increment", jobId: a?.job?._id },
            })
          );
        });
      }
    });
  };

  let allChats = useSelector((state) => state.job.allChats);

  useEffect(() => {
    if (chats?.length) {
      if (user.role === "loved-one") {
        for (let i = 0; i < chats.length; i++) {
          socket.emit("joinRoom", chats[i]?._id);
        }
      } else {
        for (let i = 0; i < chats.length; i++) {
          socket.emit("joinRoom", chats[i]?.job?._id);
        }
      }
    }
  }, [chats]);
  useEffect(() => {
    if (user) {
      dispatch(getChatHead()).then((res) => {
        if (res?.payload?.data) {
          let count = res?.payload?.data?.reduce((a, b) => a.count + b.count);
          if (count > 0) {
            document.getElementById("badgeNew").style.display = "flex";
            document.getElementById("badgeNew").innerHTML = count;
          } else {
            document.getElementById("badgeNew").style.display = "none";
          }
        }
      });
    }
  }, [user]);
  const onInputFocus = (a) => {
    let id = "";
    if (user?.role == "loved-one") {
      id = a?._id;
    } else {
      id = a?.job?._id;
    }
    dispatch(
      updateChatHead({ id: user?._id, data: { type: "", jobId: id } })
    ).then(() => {
      dispatch(getChatHead()).then((res) => {
        if (res?.payload?.data) {
          let count = res?.payload?.data?.reduce((a, b) => a.count + b.count);
          if (count > 0) {
            document.getElementById("badgeNew").style.display = "flex";
            document.getElementById("badgeNew").innerHTML = count;
          } else {
            document.getElementById("badgeNew").style.display = "none";
          }
        }
      });
    });
  };
  return (
    <div className="chatComponentMain">
      <div className="allChats" id="allChat">
        <div className="chatHeader" onClick={() => openChatHead("allChat")}>
          <div className="heading text-center d-flex align-items-center justify-content-center">
            <h6>Messages</h6>
          </div>
        </div>

        <div className="searchMsgbox p-2">
          <div className="inputDiv">
            <input type="text" placeholder="Search" />
          </div>
        </div>

        <div className="chatList">
          {chats?.map((a, i) =>
            user.role === "loved-one" ? (
              <div key={i} className="list" onClick={() => setChat(a)}>
                <div className="imgDiv">
                  <img
                    src={
                      a?.applications[0]?.applicant?.image
                        ? `${a?.applications[0]?.applicant?.image}`
                        : "https://static.vecteezy.com/system/resources/thumbnails/000/439/863/small/Basic_Ui__28186_29.jpg"
                    }
                  />
                </div>
                <div className="nameDiv">
                  <div className="heading">
                    <h6>{a?.applications[0]?.applicant?.name}</h6>
                  </div>
                  <div className="text-div">
                    <p>{a?.title}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div key={i} className="list" onClick={() => setChat(a)}>
                <div className="imgDiv">
                  <img
                    src={
                      a?.createdBy?.image
                        ? `${a?.createdBy?.image}`
                        : "https://static.vecteezy.com/system/resources/thumbnails/000/439/863/small/Basic_Ui__28186_29.jpg"
                    }
                  />
                </div>
                <div className="nameDiv">
                  <div className="heading">
                    <h6>{a?.createdBy?.name}</h6>
                  </div>
                  <div className="text-div">
                    <p>{a?.job?.title}</p>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {openChats.map((a, i) => (
        <div key={i} className="allChats chatHead" id={`chat-${a._id}`}>
          <div
            className="chatHeader"
            onClick={() => openChatHead(`chat-${a._id}`)}
          >
            <div className="heading text-center d-flex align-items-center justify-content-between w-100">
              {user?.role === "loved-one" ? (
                <h6>{a?.applications[0]?.applicant?.name}</h6>
              ) : (
                <h6>{a?.createdBy?.name}</h6>
              )}
              <svg
                onClick={() => removeChat(i)}
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="#fff"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </div>
          </div>
          <div className="messagesDiv">
            <div className="messages" id={`message-${a._id}`}>
              {user?.role === "loved-one"
                ? allChats
                    .filter((c) => c.project_id === a._id)[0]
                    ?.chats.map((b, i) =>
                      b.sender_id?._id === user._id ? (
                        <div className="message to">{b.message}</div>
                      ) : (
                        <div className="message from">{b.message}</div>
                      )
                    )
                : allChats
                    .filter((c) => c.project_id === a?.job?._id)[0]
                    ?.chats.map((b, i) =>
                      b.sender_id?._id === user._id ? (
                        <div className="message to">{b.message}</div>
                      ) : (
                        <div className="message from">{b.message}</div>
                      )
                    )}
            </div>
            <form onSubmit={(e) => send(a, e)}>
              <div className="messageBox">
                <input
                  value={text}
                  type="text"
                  placeholder="Type a message"
                  onChange={(e) => setText(e.target.value)}
                  onFocus={() => onInputFocus(a)}
                />
                <button type="submit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-send-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ChatComponent;
