import { Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import "./Common/Common.scss";

import { ToastContainer } from "react-toastify";
import DashboardLayout from "./DashboardLayout";
import Layout from "./Layout";
import ForgotPass from "./Screens/AuthScreens/ForgotPass/ForgotPass";
import Login from "./Screens/AuthScreens/Login/Login";
import Register from "./Screens/AuthScreens/Register/Register";
import ResetPass from "./Screens/AuthScreens/ResetPass/ResetPass";
import Blogs from "./Screens/Blogs/Blogs";
import Contact from "./Screens/Contact/Contact";
import Home from "./Screens/Home/Home";
import Jobs from "./Screens/Jobs/Jobs";
import Dashboard from "./Screens/Portal/Dashboard/Dashboard";
import Faqs from "./Screens/faqs/faqs";

import { useSelector } from "react-redux";
import ChatComponent from "./Components/ChatComponent/ChatComponent";
import Chat from "./Screens/Chat";
import JobDetail from "./Screens/Jobs/JobDetail";
import IdentityVerify from "./Screens/Portal/IdentityVerify/IdentityVerify";
import Applicants from "./Screens/Portal/Jobs/Applicants";
import AppliedJobs from "./Screens/Portal/Jobs/AppliedJobs";
import CreateJob from "./Screens/Portal/Jobs/CreateJob";
import FinishedJobs from "./Screens/Portal/Jobs/FinishedJobs";
import JobHistory from "./Screens/Portal/Jobs/JobHistory";
import JobsInProgress from "./Screens/Portal/Jobs/JobInProgress";
import OngoingJobs from "./Screens/Portal/Jobs/OngoingJobs";
import "./Screens/Portal/Portal.scss";
import Profile from "./Screens/Portal/Profile/Profile";
import PurchaseCredits from "./Screens/Portal/PurchaseCredits/PurchaseCredits";
import Transaction from "./Screens/Portal/Transaction";
import WithdrawHistory from "./Screens/Portal/Withdraw/WithdrawHistory";
import WithdrawNow from "./Screens/Portal/Withdraw/WithdrawNow";
import UserProfile from "./Screens/UserProfile/UserProfile";
import SubcriptionScreen from "./Screens/SubcriptionScreen/SubscriptionScreen";
import EditJob from "./Screens/Portal/Jobs/EditJob";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import TermAndCondition from "./Screens/TermAndCondition";
import { io } from "socket.io-client";
import ChangePassword from "./Screens/ChangePassword/ChangePassword";

const socket = io("https://kaytreebackend-63da29f5d5cb.herokuapp.com/");
// const socket = io("https://kaytreebackend-63da29f5d5cb.herokuapp.com/");

function App() {
  let user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const isChatRoute = location.pathname.startsWith("/chat");

  useEffect(() => {
    if (user?._id) {
      socket.emit("joinJobRoom");
    }
  }, [user]);

  return (
    <div className="App">
      {user && !isChatRoute && <ChatComponent />}
      <ToastContainer />
      {/* <Router> */}
      <Routes>
        {/* Home Page Route */}
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        {/* Home Page Route End */}

        {/* Auth Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPass />} />
        {/* Auth Routes End */}

        {/* Other Routes */}
        <Route
          path="/jobs/:categories?"
          // path="/jobs"
          element={
            <Layout>
              <Jobs />
            </Layout>
          }
        />
        <Route
          path="/jobDetail/:id"
          element={
            <Layout>
              <JobDetail />
            </Layout>
          }
        />
        <Route
          path="/faqs"
          element={
            <Layout>
              <Faqs />
            </Layout>
          }
        />
        <Route
          path="/PrivacyPolicy"
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
        />
        <Route
          path="/TermAndCondition"
          element={
            <Layout>
              <TermAndCondition />
            </Layout>
          }
        />
        <Route
          path="/blogs"
          element={
            <Layout>
              <Blogs />
            </Layout>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        {/* Other Routes End */}

        {/* Messages Route */}
        <Route
          path="/chat/:id?"
          element={
            <Layout>
              <Chat />
            </Layout>
          }
        />

        {/* Subscription Route */}
      
        <Route
          path="/subscription/"
          element={
            <Layout>
              <SubcriptionScreen />
            </Layout>
          }
        />

        {/* Portal Routes */}

        {/* Dashboard */}
        <Route
          path="/user/dashboard"
          element={
            <DashboardLayout name="Dashboard">
              <Dashboard />
            </DashboardLayout>
          }
        />

        {/* Purchase Credit */}
        <Route
          path="/user/purchase-credit"
          element={
            <DashboardLayout name="Purchase Credit">
              <PurchaseCredits />
            </DashboardLayout>
          }
        />

        {/* Profile */}
        <Route
          exact
          path="/user/profile-setting"
          element={
            <DashboardLayout name="Profile Setting">
              <Profile />
            </DashboardLayout>
          }
        />

        {/* Jobs */}
        <Route
          path="/user/job/create"
          element={
            <DashboardLayout defaultActive="1" name="Create Job">
              <CreateJob />
            </DashboardLayout>
          }
        />
        <Route
          path="/user/job/edit/:id"
          element={
            <DashboardLayout defaultActive="1" name="Edit Job">
              <EditJob />
            </DashboardLayout>
          }
        />
        <Route
          path="/user/job/history"
          element={
            <DashboardLayout defaultActive="1" name="Jobs History">
              <JobHistory />
            </DashboardLayout>
          }
        />
        <Route
          path="/user/job/ongoing-jobs"
          element={
            <DashboardLayout defaultActive="1" name="Ongoing Jobs">
              <OngoingJobs />
            </DashboardLayout>
          }
        />
        <Route
          path="/user/job/applicants/:id"
          element={
            <DashboardLayout defaultActive="1" name="Jobs History">
              <Applicants />
            </DashboardLayout>
          }
        />
        <Route
          path="/user/job/finished"
          element={
            <DashboardLayout defaultActive="1" name="Complete Jobs History">
              <FinishedJobs />
            </DashboardLayout>
          }
        />
        <Route
          path="/user/job/applied"
          element={
            <DashboardLayout defaultActive="1" name="Applied Jobs">
              <AppliedJobs />
            </DashboardLayout>
          }
        />
        <Route
          path="/user/job/in-progress"
          element={
            <DashboardLayout defaultActive="1" name="Jobs In Progress">
              <JobsInProgress />
            </DashboardLayout>
          }
        />

        {/* Deposits */}
        <Route
          path="/user/deposit/now"
          element={
            <DashboardLayout
              defaultActive="2"
              name="Deposit Methods"
            ></DashboardLayout>
          }
        />
        <Route
          path="/user/deposit/history"
          element={
            <DashboardLayout
              defaultActive="2"
              name="Deposit History"
            ></DashboardLayout>
          }
        />

        {/* Withdraw */}
        <Route
          exact
          path="/user/withdraw/now"
          element={
            <DashboardLayout defaultActive="3" name="Withdraw Now">
              <WithdrawNow />
            </DashboardLayout>
          }
        />
        <Route
          exact
          path="/user/withdraw/history"
          element={
            <DashboardLayout defaultActive="3" name="Withdraw History">
              <WithdrawHistory />
            </DashboardLayout>
          }
        />

        {/* Transactions */}
        <Route
          exact
          path="/user/transactions"
          element={
            <DashboardLayout name="Transactions">
              <Transaction />
            </DashboardLayout>
          }
        />

        {/* Change Password */}
        <Route
          exact
          path="/user/change-password"
          element={
            <DashboardLayout name="Change Password">
              <ChangePassword />
            </DashboardLayout>
          }
        />

        {/* Identity Verification */}
        <Route
          exact
          path="/user/identity-verification"
          element={
            <DashboardLayout name="Identity Verification">
              <IdentityVerify />
            </DashboardLayout>
          }
        />

        <Route
          exact
          path="/user/:id"
          element={<DashboardLayout name="Dashboard"></DashboardLayout>}
        />
        <Route
          exact
          path="/user/:id/:id"
          element={<DashboardLayout name="Dashboard"></DashboardLayout>}
        />
        {/* Portal Routes End */}

        <Route
          exact
          path="/profile/:id"
          element={
            <Layout>
              <UserProfile />
            </Layout>
          }
        />
      </Routes>
      {/* </Router> */}
    </div>
  );
}

export default App;
