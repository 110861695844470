import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Pagination,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showErrorToast } from "../../apiService";
import { getUser, reportUser } from "../../redux/userSlice/actions";
import Rating from "react-rating-stars-component";
import { getUserReviews } from "../../redux/jobSlice/actions";
import "./UserProfile.css";
import "./UserProfile.scss";

function UserProfile() {
  let dispatch = useDispatch();
  let user = useSelector((state) => state.user?.user);
  
  let { userReviews, totalReviews } = useSelector((state) => state.job);
  const totalPage = useSelector((state) => state.job.userReviewsTotal);
  console.log(totalPage);

  const [reason, setReason] = useState("");
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    let id =
      window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
      ];
    dispatch(getUser(id));
    dispatch(getUserReviews({ page: currentPage, limit: 5, id }));
  }, [dispatch, currentPage]);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const report = () => {
    if (!reason) {
      showErrorToast("Select any reason");
    } else {
      let obj = {
        reportTo: user?._id,
        reportReason: reason,
      };

      dispatch(reportUser(obj)).then(() => {
        setShow(false);
      });
    }
  };
  const paginationItems = [];
  for (let number = 1; number <= totalPage; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="userProfile">
      <Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Report User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Select reason for reporting:</Form.Label>
            <Form.Control
              as="select"
              value={reason}
              onChange={handleReasonChange}
            >
              <option value="">Select Reason</option>
              <option value="Spam">Spam</option>
              <option value="Abusive behavior">Abusive behavior</option>
              <option value="Inappropriate content">
                Inappropriate content
              </option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-div" style={{ columnGap: 10, display: "flex" }}>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={report}>
              Report
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="bgtop"></div>

      <Container>
        <Row className="justify-content-md-center align-items-center mt-3">
          <Col md="3">
            <div className="userimg">
              <Image
                height={150}
                width={150}
                src={
                  user.image ??
                  "https://script.viserlab.com/microlab/assets/images/user/profile/user.png"
                }
                fluid
                style={{ borderRadius: "50%", border: "10px white solid" }}
              />
            </div>
            <div className="btn-div btn-divd">
              <Button variant="primary" onClick={() => setShow(true)}>
                Report
              </Button>
            </div>
          </Col>
          <Col md="6" className="d-flex flex-column justify-content-center">
            <h4>{user.name}</h4>
            <p>
              <strong>Email:</strong> {user.email}
            </p>
            <p>
              <strong>Verified:</strong> {user.verified ? "Yes" : "No"}
            </p>
            <p>
              <strong>Member Since:</strong>{" "}
              {new Date(user.createdAt).toLocaleDateString()}
            </p>
          </Col>
          <Col md="3">
          <p>
              <strong>Bio:</strong>{" "}
              {user.bios}
            </p>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container className="pt-5">
        <div className="heading text-center mb-4">
          <h3>Reviews ({totalReviews})</h3>
        </div>
        {userReviews?.map((e, i) => (
          <div className="userReview">
            <Row className="justify-content-md-center align-items-center">
              <Col md="2" className="text-center">
                <Image
                  height={80}
                  width={80}
                  src={
                    e?.reviewBy.image ??
                    "https://script.viserlab.com/microlab/assets/images/user/profile/user.png"
                  }
                  fluid
                  style={{ borderRadius: "50%" }}
                />
                <div className="heading">
                  <h6 className="mt-2">{e?.reviewBy?.name}</h6>
                </div>
              </Col>
              <Col md="10">
                <div>
                  <Rating
                    count={5}
                    size={25}
                    edit={false}
                    activeColor="#ffd700"
                    value={e?.rating}
                  />
                </div>
                <div>
                  <p>{e.review}</p>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </Container>
      <Pagination className="justify-content-center mt-4 pagination-custom">
        {paginationItems}
      </Pagination>
    </div>
  );
}

export default UserProfile;
