import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import VerificationModal from "../../../Components/VerificationModal/VerificationModal";
import { getFormValues } from "../../../helper";
import { registerUser } from "../../../redux/authSlice/actions";
import WelcomeModal from "../../../Components/WelcomeModal/WelcomeModal";

function Register() {
  let [show, setShow] = useState(false);
  let [showWelcome, setShowWelcome] = useState(false);
  let [email, setEmail] = useState("");
  let [role, setRole] = useState("companion");

  let [errMsg, setErrMsg] = useState("");

  let dispatch = useDispatch();
  let error = useSelector((state) => state.auth.error);
  useEffect(() => {
    console.log(error);

    // Check if the user has seen the welcome modal before
    const hasSeenWelcome = localStorage.getItem("hasSeenWelcome");

    if (!hasSeenWelcome) {
      setShowWelcome(true); // Show the WelcomeModal if first time
    }
  }, [error]);
  useEffect(() => {
    console.log(error);
  }, [error]);

  const signupUser = (e) => {
    e.preventDefault();
    let obj = getFormValues(e);
    let obj2 = {
      name: obj.name,
      email: obj.email,
      password: obj.password,
      confirmPassword: obj.confirmPassword,
      role: role,
    };
    dispatch(registerUser({ payload: obj2 })).then((res) => {
      if (res.payload?.status === "success") {
        setShow(true);
      }
    });
  };
  const onHide = () => {
    setShowWelcome(!showWelcome);
  };
  return (
    <div className="authMain loginMain container-fluid">
      {/* Varification Modal */}
      <VerificationModal email={email} show={show} />

      <Row className="h-100">
        <Col md={6} className="h-100 p-0 d-none d-md-block">
          <div className="imageDiv d-flex flex-column align-items-center justify-content-center row-gap-5">
            {/* <div className='logoDiv'>
                            <img width={100} src={require('../../../assets/images/logo.png')} />
                        </div> */}
            <div className="imgDiv text-center">
              <img
                width={"80%"}
                src={require("../../../assets/images/person.png")}
              />
            </div>
          </div>
        </Col>

        <Col md={6} className="h-100 p-0">
          <div className="formDiv d-flex flex-column align-items-center justify-content-center login h-100">
            <div className="logoDiv d-block d-md-none mb-5">
              <img
                width={120}
                src={require("../../../assets/images/logo.png")}
              />
            </div>
            <div className="heading w-100">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <h6>
                  Welcome to <span>Kaytree</span>
                </h6>
              </div>
              <h2>
                {/* {role === "loved-one" ? "Loved-one" : "Companion"} {" "} */}
                Register
              </h2>
            </div>

            <form onSubmit={(e) => signupUser(e)}>
              <div className="tabDiv mb-3">
                <Row>
                  <Col
                    md={12}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div
                      onClick={() => setRole("companion")}
                      className={`checkboxSelect ${
                        role === "companion" && "active"
                      }`}
                    >
                      <p>I want to Work</p>
                    </div>
                    <div
                      onClick={(e) => setRole("loved-one")}
                      className={`checkboxSelect ${
                        role === "loved-one" && "active"
                      }`}
                    >
                      <p>I want to Hire</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="inputDiv">
                <label>Name</label>
                <input name="name" type="text" placeholder="Type your Name" />
              </div>
              <div className="inputDiv">
                <label>Email</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  placeholder="Type your Email"
                />
              </div>
              <div className="inputDiv">
                <label>Password</label>
                <input
                  name="password"
                  type="password"
                  placeholder="Type your Password"
                />
              </div>
              <div className="inputDiv">
                <label>Confirm Password</label>
                <input
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                />
              </div>

              {/* {error?.message &&
                                <div className='error'>
                                    <Alert variant="danger">
                                        {error?.message}
                                    </Alert>
                                </div>
                            } */}

              <div className="btn-div">
                <button type="submit">Register</button>
              </div>
            </form>

            <div className="formBelow text-center">
              <p>
                Already have an account?{" "}
                <NavLink className="ml-1" to="/login">
                  Login
                </NavLink>
              </p>
            </div>
          </div>
        </Col>
      </Row>

      {/* Welcome Modal */}
      <WelcomeModal
        show={showWelcome}
        role={role}
        setRole={setRole}
        onHide={onHide}
      />
    </div>
  );
}

export default Register;
