import React, { useEffect, useState } from "react";
import { Col, Container, Image, Modal, Pagination, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetail,
  updateUserData,
  upload,
} from "../../../redux/authSlice/actions";
import { getUserReviews } from "../../../redux/jobSlice/actions";
import Rating from "react-rating-stars-component";
import AutocompleteInput from "../Jobs/Autocomplete";

function Profile(props) {
  let dispatch = useDispatch();

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    address: "",
    state: "",
    zip: "",
    city: "",
    country: "",
    image: "",
    latitude: "",
    longitude: "",
    dob: "",
    bios: ""
  });

  let user = useSelector((state) => state.auth.user);
  let { userReviews, totalReviews } = useSelector((state) => state.job);
  const totalPage = useSelector((state) => state.job.userReviewsTotal);
  console.log(userReviews);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (user?._id) {
      dispatch(getUserReviews({ page: currentPage, limit: 5, id: user._id }));
    }
  }, [currentPage, user]);

  const uploadImage = (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);

    dispatch(upload({ payload: formData, upload: true })).then((res) => {
      if (res.payload?.status === "success") {
        let data = { image: res.payload?.data?.url };
        dispatch(updateUserData({ payload: data })).then((ress) => {
          if (ress.payload?.status === "success") {
            setUserData(data);
          }
        });
      }
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Field: ${name}, Value: ${value}`); // Debugging
    setUserData({ ...userData, [name]: value });
  };

  useEffect(() => {
    if (user) {
      console.log(user);
      setUserData({
        name: user.name,
        email: user.email,
        address: user.address,
        state: user.state,
        zip: user.zip,
        city: user.city,
        country: user.country,
        image: user.image,
        latitude: user.latitude,
        longitude: user.longitude,
        dob: user.dob,
        bios: user.bios
      });

    
    }
  }, [user]);

  const updateUser = (e) => {
    e.preventDefault();

    dispatch(updateUserData({ payload: userData })).then((res) => {
      if (res.payload?.status === "success") {
        dispatch(getUserDetail());
      }
    });
  };

  const paginationItems = [];
  for (let number = 1; number <= totalPage; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    if (user) {
      handleShowModal()
    }
  }, [user])

  return (
    <div className="profileMain shadow p-3">

      {props.modal && user &&
        <div className="mb-4">
          <div className='heading text-center'>
            <h5>Step 2</h5>
          </div>
          <div className='text-div text-center mt-2'>
            <p>Complete your Profile Details and Upload Picture.</p>
          </div>

        </div>
      }

      <form onSubmit={(e) => updateUser(e)}>
        <Row>
          <h1 class="text-center pricing-table-title">Step 2 of 4: Complete Profile and Upload your Picture</h1>
          <Col md={4}>
            <div
              className="imgDiv"
              style={
                userData?.image
                  ? { backgroundImage: `url(${userData.image})` }
                  : {}
              }
            ></div>
            <div className="heading mt-3">
              <h5 className="text-center">{user?.name}</h5>
              <h6 className="text-center">{user?.email}</h6>
            </div>
            <div className="btn-div">
              <button type="button">
                Upload Profile Picture
                <input onChange={(e) => uploadImage(e)} type="file" />
              </button>
            </div>
          </Col>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <div className="inputDiv">
                  <label>
                    Name<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={userData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="inputDiv">
                  <label>Email Address</label>
                  <input type="email" disabled value={user?.email} />
                </div>
              </Col>
              <Col md={6}>
                <AutocompleteInput title="Address" setFormData={setUserData} formData={userData} />
              </Col>
              <Col md={6}>
                <div className="inputDiv">
                  <label>State</label>
                  <input
                    type="text"
                    name="state"
                    value={userData.state}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className="inputDiv">
                  <label>Zip Code</label>
                  <input
                    type="text"
                    name="zip"
                    value={userData.zip}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="inputDiv">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    value={userData.city}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="inputDiv">
                  <label>Country</label>
                  <input
                    type="text"
                    name="country"
                    value={userData.country}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="inputDiv">
                  <label>Date Of Birth</label>
                  <input
                    name="dob"
                    value={userData.dob}
                    onChange={handleInputChange}
                    type="date" />
                </div>
              </Col>
              <Col md={12}>
                <div className="inputDiv">
                  <label>Bio</label>
                  <textarea 
                    name="bios"
                    value={userData.bios}
                    onChange={handleInputChange}
                    placeholder="About myself and why have I chosen to work with Kaytree? explain in a few words why people would want to work with you. Your Bio is visible to others." 
                    rows="6"       
                    cols="40"    
                    style={{resize: 'vertical'}} 
                   />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="btn-div">
          <button type="submit" className="primary">
            Submit
          </button>
        </div>
      </form>
      <Container className="pt-5">
        <div className="heading text-center mb-4">
          <h3>Reviews ({totalReviews})</h3>
        </div>
        {userReviews?.map((e, i) => (
          <div className="userReview">
            <Row className="justify-content-md-center align-items-center">
              <Col md="2" className="text-center">
                <Image
                  height={80}
                  width={80}
                  src={
                    e?.reviewBy.image ??
                    "https://script.viserlab.com/microlab/assets/images/user/profile/user.png"
                  }
                  fluid
                  style={{ borderRadius: "50%" }}
                />
                <div className="heading">
                  <h6 className="mt-2">{e?.reviewBy?.name}</h6>
                </div>
              </Col>
              <Col md="10">
                <div>
                  <Rating
                    count={5}
                    size={25}
                    edit={false}
                    activeColor="#ffd700"
                    value={e?.rating}
                  />
                </div>
                <div>
                  <p>{e.review}</p>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </Container>
      <Pagination className="justify-content-center mt-4 pagination-custom">
        {paginationItems}
      </Pagination>
    </div>
  );
}

export default Profile;
