import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

import "./WelcomeModal.scss";

function WelcomeModal(props) {
  return (
    <div className="verificationModalMain">
      <Modal size="md" centered show={props.show} onHide={props.onHide}>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-12 modalMain">
              <div
                className="card bg-white mb-3 mt-3 border-0"
                style={{ boxShadow: "0 12px 15px rgba(0, 0, 0, 0.02)" }}
              >
                <div className="card-body p-4 text-center">
                  <h5>Welcome to Kaytree!</h5>
                  <div className="tabDiv mb-3">
                    <Row>
                      <Col
                        md={12}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div
                            onClick={() => props.setRole("companion")}
                          className={`checkboxSelect ${
                            props.role === "companion" && "active"
                          }`}
                        >
                          <p>Companion</p>
                        </div>
                        <div
                            onClick={(e) => props.setRole("loved-one")}
                          className={`checkboxSelect ${
                            props.role === "loved-one" && "active"
                          }`}
                        >
                          <p>Loved-one</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <p>
                    {props.role === "loved-one"
                      ? ` Enjoy exclusive benefits while empowering the next
                    generation!`
                      : `Work here as a valued companion and enjoy amazing benefits!`}
                  </p>
                  <p style={{marginBottom:"0px"}}>
                    {props.role === "loved-one"
                      ? `We’re thrilled to have you join us, dear loved one, in supporting talented young individuals. Together, let’s create meaningful opportunities and build a brighter future.`
                      : `Be part of our supportive community where growth, creativity, and well-being come first.`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WelcomeModal;
